import React from 'react';

import Layout from '$components/layout';
import SEO from '$components/seo';

const ChiSiamo = () => (
  <Layout>
    <h1>Lavori in corso!</h1>
  </Layout>
);

export default ChiSiamo;
